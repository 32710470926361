import axios, { AxiosResponse } from 'axios';
import { Config } from '../config/env';
import { isEmpty } from 'lodash';

const apiUrl = Config.REPORT_API_URL;

export const listCarrierTransactionsService = async (
  carrierUuid: string,
  startDate: string,
  endDate: string,
  driverUuid?: string | string[],
  unitNumber?: string,
  selectedCategories?: string[],
): Promise<AxiosResponse> => {
  return await axios
    .get(
      `${apiUrl}/reports/carriers/${carrierUuid}/transactions?startDateTime=${startDate}&endDateTime=${endDate}${driverUuid ? `&driverUuid=${driverUuid}` : ''}${unitNumber ? `&unitNumber=${unitNumber}` : ''}${!isEmpty(selectedCategories) ? `&product_categories=${selectedCategories}` : ''}`,
    )
    .then((response) => response)
    .catch((error) => error);
};

export async function getTransactionsServiceV2({
  carrierUuid,
  startDate,
  endDate,
  fileType,
  formatType,
  driverUuid,
  unitNumber,
  selectedCategories,
}: {
  carrierUuid: string;
  startDate: string;
  endDate: string;
  fileType: string;
  formatType?: string;
  driverUuid?: string | string[];
  unitNumber?: string;
  selectedCategories?: string[];
}) {
  return await axios
    .get(
      `${Config.REPORT_API_URL}/reports/carriers/${carrierUuid}?fileType=${fileType}${formatType ? `&formatType=${formatType}` : ''}&startDateTime=${startDate}&endDateTime=${endDate}${driverUuid ? `&driverUuid=${driverUuid}` : ''}${unitNumber ? `&unitNumber=${unitNumber}` : ''}${!isEmpty(selectedCategories) ? `&product_categories=${selectedCategories}` : ''}`,
    )
    .then((response) => response)
    .catch((error) => error);
}

export const driverReport = async (
  carrierUuid: string,
  startDate: string,
  endDate: string,
): Promise<AxiosResponse> => {
  return await axios
    .get(
      `${apiUrl}/reports/carriers/${carrierUuid}/drivers?startDateTime=${startDate}&endDateTime=${endDate}`,
    )
    .then((response) => response)
    .catch((error) => error);
};

export const driverSummaryReport = async (
  carrierUuid: string,
  drivers: string | string[],
  startDate: string,
  endDate: string,
): Promise<AxiosResponse> => {
  // Create the driverUuids query string part by mapping through the drivers array
  const driverParams = Array.isArray(drivers)
    ? drivers
        .map((driver) => `&driverUuids=${encodeURIComponent(driver)}`)
        .join('')
    : `&driverUuids=${encodeURIComponent(drivers)}`;

  // Construct the full URL with the driverParams appended
  const url = `${apiUrl}/reports/drivers/all/${carrierUuid}/summary?startDateTime=${encodeURIComponent(startDate)}&endDateTime=${encodeURIComponent(endDate)}${driverParams}`;

  return await axios
    .get(url)
    .then((response) => response)
    .catch((error) => error);
};

export const listDiscountReport = async (
  carrierUuid: string,
): Promise<AxiosResponse> => {
  return await axios
    .get(`${apiUrl}/reports/carriers/${carrierUuid}/earnedDiscounts`)
    .then((response) => response)
    .catch((error) => error);
};

export const listFuelTaxReport = async (
  carrierUuid: string,
  startDate: string,
  endDate: string,
): Promise<AxiosResponse> => {
  return await axios
    .get(
      `${apiUrl}/reports/carriers/${carrierUuid}/fueltax?startDateTime=${startDate}&endDateTime=${endDate}`,
    )
    .then((response) => response)
    .catch((error) => error);
};
